<template>
  <div v-frag>
    <v-row dense no-gutters>
      <login-left/>
      <login-right/>
      <v-col cols='12' lg='1' md='1' sm='1' xl='1' xs='12'></v-col>
    </v-row>
  </div>
</template>
<script>
import image from '../../assets/images/image1.jpeg'
import quote from '../../assets/images/quote.png'
import LoginLeft from './components/LoginLeft'
import LoginRight from './components/LoginRight'
import {mapActions} from 'vuex'

export default {
  name: 'Login',
  components: {
    LoginLeft,
    LoginRight
  },
  data: () => ({
    show: false,
    image: image,
    quote: quote,
    valid: true
  }),
  methods: {
    ...mapActions('cmsStore', ['getLoginContent'])
  },
  async created() {
    await this.getLoginContent()
  }
}
</script>

<style scoped>
.low-angle {
  background-color: transparent;
  border-right: white solid 10px;
  border-bottom: white solid 10px;
  float: right;
}
</style>
