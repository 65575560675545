<template>
  <v-col cols='12' lg='6' md='6' sm='6' xl='6' xs='12'>
    <v-card color='white' elevation='0' height='100%' width='100%'>
      <v-container class='pl-15 pr-15' fill-height>
        <v-row
          align='center'
          align-content='center'
          class='text-center'
          justify='center'
        >
          <v-col cols='12'>
            <div class='d-flex justify-center'>
              <v-img :src='loginPageContent.logo || image' contain height='200' width='200'></v-img>
            </div>
          </v-col>
          <v-col cols='12'>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              {{ loginPageContent.login_title }}
            </div>
          </v-col>
          <v-col class='mt-3' cols='8'>
            <v-divider></v-divider>
          </v-col>
          <v-col cols='8' dense>
            <v-form
              ref='form'
              v-model='valid'
              lazy-validation
              @submit.prevent='login'
            >
              <div
                class='
                  text-caption
                  grey--text
                  text--darken-2 text-start
                  mb-2
                  font-weight-bold
                '
              >
                {{ loginPageContent.email_input_title }}
              </div>
              <v-text-field
                v-model='email'
                :rules='emailRules'
                class='rounded-lg'
                color='primary'
                hide-details='auto'
                outlined
                required
                type='email'
              ></v-text-field>

              <div
                class='
                  text-caption
                  grey--text
                  text--darken-2 text-start
                  mb-2
                  font-weight-medium
                '
              >
                {{ loginPageContent.password_input_title }}
              </div>
              <v-text-field
                v-model='password'
                :rules='passwordRules'
                :type="show ? 'text' : 'password'"
                class='rounded-lg'
                color='primary'
                hide-details='auto'
                outlined
                required
                @click:append='show = !show'
              >
                <template v-slot:append>
                  <v-btn
                    v-if='show'
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show = !show'
                  >
                    hide
                  </v-btn>
                  <v-btn
                    v-else
                    :ripple='false'
                    class='text-capitalize'
                    elevation='0'
                    plain
                    small
                    @click='show = !show'
                  >
                    show
                  </v-btn>
                </template>
              </v-text-field>
              <a
                class='font-weight-bold mt-2'
                href='/reset-password'
                style='float: right; font-size: 14px'
              >
                {{ loginPageContent.forgot_your_password_title }}
              </a>

              <v-col class='mt-8' cols='12'>
                <v-btn
                  :disabled='loading'
                  :loading='loading'
                  block
                  class='white--text body-1 text-capitalize'
                  color='primary'
                  elevation='0'
                  type='submit'
                  x-large
                  @click='login'
                >
                  {{ loginPageContent.login_button_title }}
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>
<script>
import home from '../../../services/home'
import charityService from '../../../services/charity'
import commissionService from '../../../services/mcbaCommission'
import image from '../../../assets/images/mcbalogo1.jpg'
import {mapGetters} from 'vuex'

export default {
  data: () => ({
    image: image,
    loading: false,
    show: false,
    valid: true,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    password: '',
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        (v && v.length >= 8) || 'Password must be longer than 8 characters'
    ]
  }),
  methods: {
    async login() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const res = await home.login({
            email: this.email,
            password: this.password
          })
          if (res.status === 200) {
            await this.$store.dispatch('user', res.data.data.user)
            await this.$store.dispatch('token', res.data.data.token)
            await this.$store.dispatch('operations', res.data.data.operations)
            await this.$store.dispatch('electionStore/clearElection')
            if (this.$store.getters.voted_url !== '') {
              await this.$router.replace({name: this.$store.getters.voted_url})
              await this.$store.dispatch('voted_url', '')
            } else {
              await this.$router.replace(res.data.data.operations[0].default_path)
            }
            this.loading = false
            if (res.data.data.user.id_rol === 3) {
              await this.getBankAccountProfile()
            }
            await this.getMCBACommission()
            await this.$store.dispatch('image')
          }
          this.loading = false
        } catch (err) {
          // empty
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async getBankAccountProfile() {
      const res = await charityService.getProfileBankAccount()
      this.$store.dispatch('charityBankAccount', res)
    },
    async getMCBACommission() {
      const res = await commissionService.getCommission()
      this.$store.dispatch('commission', res)
    }
  },
  computed: {
    ...mapGetters('cmsStore', ['loginPageContent'])
  }
}
</script>
